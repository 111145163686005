import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { ProductInstance } from 'models/productInstance';
import { createAction } from 'ducks/actionHelpers';

// Actions
const RE_FETCH_PRODUCT_INSTANCE_REQUEST = 'RE_FETCH_PRODUCT_INSTANCE_REQUEST';
export const RE_FETCH_PRODUCT_INSTANCE_SUCCESS = 'RE_FETCH_PRODUCT_INSTANCE_SUCCESS';
const RE_FETCH_PRODUCT_INSTANCE_FAILURE = 'RE_FETCH_PRODUCT_INSTANCE_FAILURE';

// Action creators
const reFetchProductInstanceRequest = () => createAction(RE_FETCH_PRODUCT_INSTANCE_REQUEST);
export const reFetchProductInstanceSuccess = (response: ProductInstance) =>
  createAction(RE_FETCH_PRODUCT_INSTANCE_SUCCESS, response);
const reFetchProductInstanceFailure = (err: string) =>
  createAction(RE_FETCH_PRODUCT_INSTANCE_FAILURE, err);

let reFetchProductInstanceCancel: (message?: string) => void;
export const reFetchProductInstance = (
  apiKey: string,
  id: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  if (reFetchProductInstanceCancel) {
    reFetchProductInstanceCancel();
  }
  dispatch(reFetchProductInstanceRequest());
  return axios
    .get(`${config.apiUrl}/products/-/instances/${id}?include_resource_availability=true`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      cancelToken: new axios.CancelToken(function executor(c) {
        reFetchProductInstanceCancel = c;
      }),
    })
    .then((response) => {
      dispatch(reFetchProductInstanceSuccess({ ...response.data, isReFetched: true }));
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(reFetchProductInstanceFailure('canceled'));
      } else {
        dispatch(reFetchProductInstanceFailure(err.message));
      }
    });
};

type Action =
  | ReturnType<typeof reFetchProductInstanceRequest>
  | ReturnType<typeof reFetchProductInstanceSuccess>
  | ReturnType<typeof reFetchProductInstanceFailure>;

const inFlight = (state = false, action: Action) => {
  switch (action.type) {
    case RE_FETCH_PRODUCT_INSTANCE_REQUEST:
      return true;
    case RE_FETCH_PRODUCT_INSTANCE_SUCCESS:
    case RE_FETCH_PRODUCT_INSTANCE_FAILURE:
      return false;
    default:
      return state;
  }
};

export interface ProductInstanceState {
  inFlight: boolean;
}

export default combineReducers({
  inFlight,
});
